const CompareScrollThreshold = (number, thresholdArray) => {
  let result;
  if (thresholdArray === undefined) {
    // don't filter it
    result = number;
  } else {
    // compare against the array, return the min threshold that's been scrolled past
    result = thresholdArray.reduce((accumulator, currentValue) => {
      return number >= currentValue ? currentValue : accumulator;
    }, 0);
  }
  return result;
};

export default CompareScrollThreshold;
