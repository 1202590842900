import { useState } from 'react';
import Router from 'next/router';

import { scrollTrackingEvent } from 'src/lib/analytics';
import CompareScrollThreshold from 'src/lib/utilities/CompareScrollThreshold';
import GetScrollPercent from 'src/lib/utilities/GetScrollPercent';

const useScrollTracking = () => {
  const [currentPage, setCurrentPage] = useState('');
  const [lastThreshold, setLastThreshold] = useState(0);

  const gtmScrollThresholds = [10, 25, 50, 75, 90];

  const track = () => {
    if (currentPage !== Router.asPath) {
      setCurrentPage(Router.asPath);
      setLastThreshold(0);
    }

    const percent = GetScrollPercent();
    const currentThreshold = CompareScrollThreshold(
      percent,
      gtmScrollThresholds
    );

    if (currentThreshold > lastThreshold) {
      setLastThreshold(currentThreshold);
      scrollTrackingEvent(currentThreshold);
    }
  };

  return { track };
};

export default useScrollTracking;
