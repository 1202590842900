const GetScrollPercent = () => {
  let percentScrolled = 0;

  const docElement = document.documentElement;
  const docBody = document.body;

  const getDocHeight = () => {
    return Math.max(
      docBody.scrollHeight,
      docElement.scrollHeight,
      docBody.offsetHeight,
      docElement.offsetHeight,
      docBody.clientHeight,
      docElement.clientHeight
    );
  };
  const winheight = window.innerHeight || (docElement || docBody).clientHeight;
  const scrollTop =
    window.pageYOffset ||
    (docElement || docBody.parentNode || docBody).scrollTop;
  const trackLength = getDocHeight() - winheight;

  // if trackLength is 0, then the section cannot be scrolled, so we set the result to 100%
  percentScrolled =
    trackLength === 0 ? 100 : Math.floor((scrollTop / trackLength) * 100);
  return percentScrolled;
};

export default GetScrollPercent;
